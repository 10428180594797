import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import { SortReportViewModel } from '../ViewModels/ReportViewModel';
import ReportTable from './ReportTable';

interface IWeeklyProps {
    accounts: SortReportViewModel[]
    reportLoaded: boolean;
}

export default function CustomReport(props: IWeeklyProps) {
    


    return (
        <React.Fragment>
            {!props.reportLoaded ? <LinearProgress color="primary" /> :
                <Grid container>
                    <Grid item xs={12}>
                        <ReportTable accounts={props.accounts} />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}