import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import WeeklyReport from './WeeklyReport';
import MonthlyReport from './MonthlyReport';
import DailyReport from './DailyReport';
import Grid from '@mui/material/Grid';
import Datepicker from '../Utilities/DatePicker';
import AccountExecutiveDropdown from '../Dropdowns/AccountExecutiveDropdown';
import { Filters, SortOppReportViewModel, SortReportViewModel } from '../ViewModels/ReportViewModel';
import { useLocation } from 'react-router';
import MonthlyOppReport from './MonthlyOppReport';
import WeeklyOppReport from './WeeklyOppReport';
import DailyOppReport from './DailyOppReport';
import CustomOppReport from './CustomOppReport';
import CustomReport from './CustomReport';
import authService from '../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import { CSVLink } from "react-csv";
import ClientDropdown from '../Dropdowns/ClientDropdown';
import TeamLeaderDropdown from '../Dropdowns/TeamLeaderDropdown';
import TeamDropdown from '../Dropdowns/TeamDropdown';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { PermissionCheck } from '../Contexts/PermissionCheck';
import CopyWriterDropdown from '../Dropdowns/CopyWriterDropdown';
import ClientDirectorDropdown from '../Dropdowns/ClientDirectorDropdown';
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

class DropdownViewModel {
    id: string = '';
    value: string = '';
}

export default function ReportDashboard() {
    const location = useLocation();
    const isOpportunity = location.pathname.includes("Opportunity");

    const [tab, setTab] = React.useState(0);
    const [date, setDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [searchFilters, setSearchFilters] = React.useState<Filters>(new Filters());
    const [client, setClient] = React.useState('');
    const today = new Date();
    today.setDate(1);
    const [month, setMonth] = React.useState(today);
    const [list, setList] = React.useState<DropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);
    const [reportLoaded, setReportLoaded] = React.useState(true);
    const [csvDataR, setCsvDataR] = React.useState<SortReportViewModel[]>([]);
    const csvHeadersR = [
        { key: 'campaignName', label: 'Client' },
        { key: 'name', label: 'Account' },
        { key: 'packageName', label: 'Package' },
        { key: 'country', label: 'Country' },
        { key: 'monthlyPrice', label: 'Monthly Price' },
        { key: 'connections', label: 'Connections' },
        { key: 'messages', label: 'Messages' },
        { key: 'acceptanceRate', label: 'Acceptance Rate' },
        { key: 'opportunitiesEmail', label: 'Emails Ops.' },
        { key: 'opportunitiesPhone', label: 'Phone Ops.' },
        { key: 'followUps', label: 'Follow Ups' },
        { key: 'pageInvites', label: 'Page Invites' },
        { key: 'freeInMails', label: 'Open profile InMails' },
        { key: 'paidInMails', label: 'InMail Credit' },
        { key: 'reportStartDate', label: 'Report From' },
        { key: 'reportEndDate', label: 'Report To' }
    ];

    const [csvData, setCsvData] = React.useState<SortOppReportViewModel[]>([]);
    const csvHeaders = [
        { key: 'client', label: 'Client' },
        { key: 'account', label: 'Account' },
        { key: 'name', label: 'Opportunity' },
        { key: 'companyName', label: 'Company' },
        { key: 'link', label: 'Link' },
        { key: 'date', label: 'Date Logged' },
        { key: 'reportStartDate', label: 'Report From' },
        { key: 'reportEndDate', label: 'Report To' }
    ];

    React.useEffect(() => {
        setLoaded(false);
        getData();

    }, []);
    React.useEffect(() => {
        const blankAccounts: SortReportViewModel[] = [];
        setCsvDataR(blankAccounts);
        const blankOpps: SortOppReportViewModel[] = [];
        setCsvData(blankOpps);
    }, [tab]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const response = await fetch('Campaign/GetStatusDropdown', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setList(data);


        setLoaded(true);
    }

    const getDailyAccounts = async () => {
        const token = await authService.getAccessToken();

        fetch(`Report/GetReports?type=daily&date=${date.toJSON()}&client=${searchFilters.client}&clientId=${searchFilters.clientId}&teamLeader=${searchFilters.teamLeaderId}&executive=${searchFilters.execId}&copyWriter=${searchFilters.copyWriterId}&clientDirector=${searchFilters.clientDirectorId}&status=${searchFilters.statusId}&team=${searchFilters.teamId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReportLoaded(true);
                setCsvDataR(data);
            })
            .catch(error => { setReportLoaded(true); });
    }
    const getDailyOpps = async () => {
        const token = await authService.getAccessToken();

        fetch(`Report/GetOpportunityReports?type=daily&date=${date.toJSON()}&client=${searchFilters.client}&clientId=${searchFilters.clientId}&executive=${searchFilters.execId}&status=${searchFilters.statusId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReportLoaded(true);
                setCsvData(data);
            })
            .catch(error => { setReportLoaded(true); });
    }
    const getWeeklyAccounts = async () => {
        const token = await authService.getAccessToken();

        fetch(`Report/GetReports?type=weekly&date=${date.toJSON()}&client=${searchFilters.client}&clientId=${searchFilters.clientId}&executive=${searchFilters.execId}&copyWriter=${searchFilters.copyWriterId}&clientDirector=${searchFilters.clientDirectorId}&teamLeader=${searchFilters.teamLeaderId}&team=${searchFilters.teamId}&status=${searchFilters.statusId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReportLoaded(true);
                setCsvDataR(data);
            })
            .catch(error => { setReportLoaded(true); });

    }
    const getWeeklyOpps = async () => {
        const token = await authService.getAccessToken();

        fetch(`Report/GetOpportunityReports?type=weekly&date=${date.toJSON()}&client=${searchFilters.client}&clientId=${searchFilters.clientId}&executive=${searchFilters.execId}&status=${searchFilters.statusId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReportLoaded(true);
                setCsvData(data);
            })
            .catch(error => { setReportLoaded(true); });
    }
    const getMonthlyAccounts = async () => {
        const token = await authService.getAccessToken();

        fetch(`Report/GetMonthlyReports?month=${month.toJSON()}&client=${searchFilters.client}&clientId=${searchFilters.clientId}&executive=${searchFilters.execId}&copyWriter=${searchFilters.copyWriterId}&clientDirector=${searchFilters.clientDirectorId}&teamLeader=${searchFilters.teamLeaderId}&status=${searchFilters.statusId}&team=${searchFilters.teamId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReportLoaded(true);
                setCsvDataR(data);
            })
            .catch(error => { setReportLoaded(true); });

    }
    const getMonthlyOpps = async () => {
        const token = await authService.getAccessToken();

        fetch(`Report/GetMonthlyOpportunityReports?month=${month.toJSON()}&client=${searchFilters.client}&clientId=${searchFilters.clientId}&executive=${searchFilters.execId}&status=${searchFilters.statusId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReportLoaded(true);
                setCsvData(data);
            })
            .catch(error => { setReportLoaded(true); });


    }
    const getCustomAccouts = async () => {
        const token = await authService.getAccessToken();

        fetch(`Report/GetReports?type=weekly&date=${date.toJSON()}&client=${searchFilters.client}&clientId=${searchFilters.clientId}&executive=${searchFilters.execId}&copyWriter=${searchFilters.copyWriterId}&clientDirector=${searchFilters.clientDirectorId}&teamLeader=${searchFilters.teamLeaderId}&endDate=${endDate.toJSON()}&status=${searchFilters.statusId}&team=${searchFilters.teamId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReportLoaded(true);
                setCsvDataR(data);
            })
            .catch(error => { setReportLoaded(true); });
    }
    const getCustomOpps = async () => {
        const token = await authService.getAccessToken();

        fetch(`Report/GetOpportunityReports?type=weekly&date=${date.toJSON()}&clientId=${searchFilters.clientId}&client=${searchFilters.client}&executive=${searchFilters.execId}&endDate=${endDate.toJSON()}&status=${searchFilters.statusId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReportLoaded(true);
                setCsvData(data);
            })
            .catch(error => { setReportLoaded(true); });

    }
    const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
        setTab(newTab);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilters({
            ...searchFilters,
            [event.target.name]: event.target.valueAsNumber || event.target.value
        });
    }
    const onStatusChange = (event: any) => {
        setSearchFilters({
            ...searchFilters,
            statusId: event.target.value as string[]
        });
    };

    const onClientChange = (value: number, name: string, client: string) => {
        setClient(client);
        setSearchFilters({
            ...searchFilters,
            client: client
        });
    }
    const onDateChange = (date: Date | null, name: string) => {
        if (tab === 2) {
            if (date !== null) {
                setMonth(date);
            }
        } else {
            if (name.includes("end")) {
                setEndDate(date as Date);
            } else {
                setDate(date as Date);
            }
        }
    }
    
    const applyFilters = () => {
        setReportLoaded(false);
        if (isOpportunity) {
            if (tab === 0) {
                getDailyOpps();
            }
            else if (tab === 1) {
                getWeeklyOpps();
            }
            else if (tab === 2) {
                getMonthlyOpps();
            }
            else if (tab === 3) {
                getCustomOpps();
            }
        }
        else {
            if (tab === 0) {
                getDailyAccounts();
            }
            else if (tab === 1) {
                getWeeklyAccounts();
            }
            else if (tab === 2) {
                getMonthlyAccounts();
            }
            else if (tab === 3) {
                getCustomAccouts();
            }
        }
    }

    const datepicker = tab === 2 ?
        <Datepicker
            monthOnly={true}
            name="month"
            label="Month"
            date={month}
            onChange={onDateChange}
            disableFuture={true}
            disablePast={false}
            disabled={!reportLoaded}
        /> :
        <Datepicker
            date={date}
            name="date"
            label={tab === 3 ? "Start Date" : ""}
            disableFuture={true}
            disablePast={false}
            onChange={onDateChange}
            disabled={!reportLoaded}
        />;


    return (
        <React.Fragment>
            <Grid container justifyContent="center">
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Daily" disabled={!reportLoaded} {...a11yProps(0)} />
                    <Tab label="Weekly" disabled={!reportLoaded}{...a11yProps(1)} />
                    <Tab label="Monthly" disabled={!reportLoaded}{...a11yProps(2)} />
                    <Tab label="Custom" disabled={!reportLoaded}{...a11yProps(3)} />
                </Tabs>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={2}>
                    {datepicker}
                </Grid>
                {tab === 3 &&
                    <Grid item xs={2}>
                        <Datepicker
                            date={endDate}
                            name="endDate"
                            label="End Date"
                            disableFuture={true}
                            disablePast={false}
                            onChange={onDateChange}
                            disabled={!reportLoaded}
                        />
                    </Grid>
                }
                <Grid item xs={2}>
                    <ClientDropdown id={searchFilters.clientId} onChange={onClientChange} isOpportunity={isOpportunity} disabled={!reportLoaded} />
                </Grid>
                {!isOpportunity &&
                    <PermissionCheck>
                        <Grid item xs={2}>
                            <AccountExecutiveDropdown id={searchFilters.execId} onChange={onChange} disabled={!reportLoaded} />
                        </Grid>
                    </PermissionCheck>
                }
                 {!isOpportunity &&
                    <PermissionCheck>
                        <Grid item xs={2}>
                            <ClientDirectorDropdown id={searchFilters.clientDirectorId} onChange={onChange} disabled={!reportLoaded} />
                        </Grid>
                    </PermissionCheck>
                }
                {!isOpportunity &&
                    <PermissionCheck>
                        <Grid item xs={2}>
                            <CopyWriterDropdown id={searchFilters.copyWriterId} onChange={onChange} disabled={!reportLoaded} />
                        </Grid>
                    </PermissionCheck>
                }
                {!isOpportunity &&
                    <PermissionCheck>
                        <Grid item xs={2}>
                            <TeamLeaderDropdown id={searchFilters.teamLeaderId} onChange={onChange} disabled={!reportLoaded} />
                        </Grid>
                    </PermissionCheck>
                }
                {!isOpportunity &&
                    <PermissionCheck>
                        <Grid item xs={2}>
                            <TeamDropdown id={searchFilters.teamId} onChange={onChange} disabled={!reportLoaded} />
                        </Grid>
                    </PermissionCheck>
                }
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        disabled={!reportLoaded}
                        select
                        label="Status"
                        name="status"
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) => (selected as string[]).join(', ')
                        }}
                        value={searchFilters.statusId}
                        onChange={onStatusChange}
                        InputProps={{
                            disabled: !loaded || !reportLoaded,
                            startAdornment: (
                                <React.Fragment>
                                    {loaded ? null : <CircularProgress size={20} />}
                                </React.Fragment>
                            )
                        }}
                    >
                        {list.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                                <Checkbox disabled={!reportLoaded} checked={searchFilters.statusId.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {!isOpportunity &&
                    <React.Fragment>
                        <Grid item>
                            <CSVLink data={csvDataR} headers={csvHeadersR} filename={client + "_report.csv"} style={{ textDecoration: 'none' }}>
                                <Button variant="contained" color="secondary" disabled={csvDataR.length <= 0 || !reportLoaded}>Export CSV</Button>
                            </CSVLink>
                        </Grid>

                    </React.Fragment>
                }
                {isOpportunity &&
                    <React.Fragment>
                        <Grid item>
                            <CSVLink data={csvData} headers={csvHeaders} filename={client + "_opportunities_report.csv"} style={{ textDecoration: 'none' }}>
                                <Button variant="contained" color="secondary" disabled={csvData.length <= 0 || !reportLoaded}>Export CSV</Button>
                            </CSVLink>
                        </Grid>

                    </React.Fragment>
                }
                <Grid item>
                    <Button disabled={!reportLoaded} variant="contained" color="secondary" onClick={applyFilters}>Apply Filters</Button>
                </Grid>
            </Grid>

            <TabPanel value={tab} index={0}>
                {isOpportunity ?
                    <DailyOppReport reportLoaded={reportLoaded} opps={csvData} />
                    :
                    <DailyReport reportLoaded={reportLoaded} accounts={csvDataR} />
                }
            </TabPanel>
            <TabPanel value={tab} index={1}>
                {isOpportunity ?
                    <WeeklyOppReport reportLoaded={reportLoaded} opps={csvData} />
                    :
                    <WeeklyReport reportLoaded={reportLoaded} accounts={csvDataR} />
                }
            </TabPanel>
            <TabPanel value={tab} index={2}>
                {isOpportunity ?
                    <MonthlyOppReport reportLoaded={reportLoaded} opps={csvData} />
                    :
                    <MonthlyReport reportLoaded={reportLoaded} accounts={csvDataR} />
                }
            </TabPanel>
            <TabPanel value={tab} index={3}>
                {isOpportunity ?
                    <CustomOppReport reportLoaded={reportLoaded} opps={csvData} />
                    :
                    <CustomReport reportLoaded={reportLoaded} accounts={csvDataR} />
                }
            </TabPanel>
        </React.Fragment>
    );
}