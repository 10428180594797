import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import { SortOppReportViewModel } from '../ViewModels/ReportViewModel';
import OppReportTable from './OppReportTable';

interface IWeeklyProps {
    reportLoaded: boolean;
    opps: SortOppReportViewModel[]
}

export default function WeeklyOppReport(props: IWeeklyProps) {

    return (
        <React.Fragment>
            {!props.reportLoaded ? <LinearProgress color="primary" /> :
                <Grid container>
                    <Grid item xs={12}>
                        <OppReportTable opps={props.opps} />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}